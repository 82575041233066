 .instructions{
   display: none;
 }

 .freezerWrap {
  width: calc(30% - 20px);
  min-width: 270px;
  margin: 20px;
}

.freezerContainer {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.headerContainer{
  width: 100%;
  padding: 10px 6px;
}

.headerTitle{
  font-size: 26px;
  font-weight: 600;
}

.headerDescription{
  font-size: 14px;
  font-weight: 300;
  opacity: .8;
  margin-top: -10px;
  padding-bottom: 28px;
  margin-bottom: 16px;
  border-bottom: 1px solid #dedede;
  /* width: calc(100% + 40px); */
  /* margin-left: -20px;
  padding-left: 20px; */
}

.cardContainer{
  background: #FFFFFF;
  box-shadow: 0px 0px 24px rgba(109, 109, 109, 0.2);
  border-radius: 8px;
  padding: 20px 10px !important;
}

.activeTag{
  background-color: #00d60e10;
  padding: 6px 16px;
  margin-right: -4px;
  border-radius: 24px;
  font-size: 12px;
  font-weight: 600;
  margin-top: -10px;
  border: 1px solid #00d60e40
}

.freezerName{
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.currentTemperature{
  padding: 0 20px;
  font-size: 15px;
  color: "#434343"
}

.setTemperature{
  padding: 0 0px;
  font-size: 15px;
  color: "#434343";
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}

.historyLink a{
padding: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #1890ff;
  border-radius: 4px;
  transition: 250ms ease;
  user-select: none;
}

.historyLink a:hover{
  border: 1px solid #4675ff80;
  color: #4675ff80 !important;
  transition: 250ms ease;
}

.expandButton{
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4675ff;
  border: 1px solid #4675ff;
  border-radius: 4px;
  color: #fff;
  transition: 250ms ease;
  user-select: none;
}

.expandButton:hover, .expandButton:focus{
  background-color: #245afd;
  color: #fff;
  transition: 250ms ease;
}

.expandSectionTitle{
  font-size: 18px;
  font-weight: 600;
  color: #434343;
}

.expandSectionLabel{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin: 2px 0;
  font-weight: 300;
  color: #434343;
  width: 100%;
}

.expandSectionValue{
  color: #434343;
  font-size: 12px;
  font-weight: 500;
}

.expandSectionSubtitle{
  font-size: 12px;
  margin: 10px 0;
  text-decoration: underline;
  font-style: italic;
  font-weight: 500;
  color: #434343;
}

.expandSectionStatusOK{
  float: right;
  font-weight: 500;
  color: #00d60e;
}

.expandSectionStatusNotOK{
  float: right;
  font-weight: 500;
  color: #ff1845;
}

body {
  background: #fff;
  font-family: 'Poppins', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  width: 120px;
  height: 80px;
  margin: 0 auto;
}

#login-container {
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  padding: 20vh 0;
}

#login-form {
  background: #fff;
  width: 100%;
  max-width: 400px;
  height: max-content;
  padding-bottom: 32px !important;
  margin: 0 auto;
  border-radius: 6px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
}

.ant-input-affix-wrapper{
  height: 38px;
}

#login-form .ant-btn{
  width: 100px;
  height: 40px;
}

#login-form .ant-form-item-required{
  font-size: 12px;
}

.chat {
  height: 60vh;
  max-height: 60vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
}

.chat .chat__message .ant-comment-inner {
  padding: 6px 0;
}

.themesTableRow:hover {
  cursor: pointer;
  background: lightcyan;
}

.showCheckbox {
  display: none !important;
}

.ant-select-selection__rendered .showCheckbox {
  display: inline-block !important;
  margin: 0 5px;
}

.disabledAddIcon {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  cursor: initial !important;
}

.notReadNotification .ant-collapse-header {
  border-left: 3px solid #faad14;
}

.redNotification {
  margin-left: 3px;
}

.ant-dropdown-link:hover {
  cursor: pointer;
}

/* .accoutingNavigator {
   margin-bottom: 30px;
} */

.accoutingNavigator a.active {
  color: #1890ff;
}

.accoutingNavigator a:hover {
  color: #1890ff;
}

.accoutingNavigator a {
  color: rgba(0, 0, 0, 0.45);
  font-size: 18px;
  margin-right: 18px;
}

.content {
  background: #fff;
  padding: 24px;
  margin: 24px;
  min-height: 280;
}

.content-xs,
.content-sm {
  padding: 6px;
  margin: 6px;
}

@media (min-width: 992px) {
  .menu {
    display: none;
  }
}

.ant-drawer-body {
  padding: 9px !important;
}

hr {
  border: 1px solid #d9d9d9;
}

.antdModal {
  width: calc(100% - 12px) !important;
}

.ant-layout{
  background-color: #FFF;
}

.ant-layout-content{
  background-color: #fff;
}

.ant-layout-header {
  background-color: #0c0c0c !important;
}

@media (max-width: 576px) {
  .freezerWrapL {
    margin: 6px !important;
  }
  .freezerContainer {
    justify-content: center;
  }
}

.ant-page-header {
  padding: 16px 0 !important;
  padding-bottom: 0 !important;
}

.ant-card{
  border-radius: 0px !important;
}

.ant-card-body {
  padding: 0 !important;
}

.ant-card-head{
  border-bottom: none !important;
}

@media (max-width: 576px) {
  .ant-page-header-heading-extra {
    display: inline !important;
    float: right !important;
    width: unset !important;
    padding-top: 0px !important;
    overflow: hidden !important;
  }
}

.ERROR {
  color: rgb(255, 54, 54);
}

.OK {
  color: rgb(60, 218, 34);
  font-weight: bold;
}
